/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-toastr/toastr";
@import "./styles/base";
@import "./styles/functions";
@import "./styles/mixins";
@import "./styles/fonts";
@import "./styles/utils";

.alert-error,
.alert-success {
  color: #ed202a;
  padding: 5px 5px 5px 0;
  margin-bottom: 0;
  @include lato-regular();
}

.modal-error {
  @include padding(0);
  @include fontSize(12);
}

.alert-success {
  color: $true-green;
  background-color: transparent;
}

.align-center-y {
  @include flexbox();
  @include align-items(center);
}

.align-center-x {
  @include flexbox();
  @include justify-content(center);
}

.required-field::after {
  content: "*";
  color: red;
  margin: 0 5px;
}

.form-group {
  margin-bottom: 1rem;
}

.section-header {
  @include lato-bold();
}

.meet-link {
  color: $dodger-blue;
  @include lato-bold();

  &:hover {
    color: $dodger-blue;
  }
}

//common style for button

.save-btn,
.draft-btn {
  @include lato-bold();
  text-transform: uppercase;
}

.reset-btn {
  text-transform: uppercase;
}

// Page header styles
.page-header {
  @include margin(10, 0);
  @include flexbox();
  @include justify-content(space-between);
}

.page-heading {
  @include lato-bold();
  @include fontSize(24);
  color: $greyish-brown;
}

.page-add-button {
  @include lato-bold();
  @include fontSize(12);
  @include borderRadius(4);
  @include padding(4, 30);
  @extend .cursor-pointer;
  text-transform: uppercase;
  box-shadow: 0 1px 2px 0 $black-20;
  background-image: linear-gradient(100deg, $btn-blue, $btn-blue-2);
  color: $white;
  outline: 0;
  border: none;
  min-height: 23px;
  align-self: center;
}
.secondary-btn{
  @extend .page-add-button;
  @include fontSize(9,true)
}
.approve-btn{
  @extend .secondary-btn;
  padding: 0px 11px;
  margin: 3px;
  @extend .center-align-flex;
}
.send-schedule-invite-btn{
  @extend .page-add-button;
  @include fontSize(12,true)
}

.no-list {
  min-height: 100px;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  @include lato-bold();
  color: $black-80;
  @include fontSize(16);
}

.nav,
.navbar {
  padding: 0 !important;
}

.separator-line {
  @include height(0.5);
  @include margin(15, 0, 15, 0);
  border-bottom: 0.5px solid $black-12;
}

.from-tab {
  display: none;

  @include tablet {
    display: block;

    &.flex {
      display: flex;
    }
  }
}

.mobile-only {
  @include tablet {
    display: none;
  }
}

@include tablet {
  .hide-tablet {
    display: none;
  }
}

@include mobile {
  .hide-mobile {
    display: none;
  }
}

.placeholder {
  @include lato-regular();
  @include fontSize(12);
}
.placeholder-grey {
  @include lato-regular();
  @include fontSize(12);
  color: $black-60;
}

.label-text {
  @include lato-regular();
  @include fontSize(12);
  color: $black-60;
}

.label-text-bold {
  @include lato-bold();
  @include fontSize(12);
  color: $black-60;
}

.note-content-red {
  @include lato-italic();
  @include fontSize(13);
}

.label-value {
  @include lato-bold();
  @include fontSize(13);
}

.label-value-main {
  @include lato-bold();
  @include fontSize(14);
}

.label-value-text {
  @include lato-regular();
  @include fontSize(14);
  color: $black-60;
}

.label-value-blue {
  color: $dodger-blue;
  @include fontSize(13);
  @include lato-regular();
}

.label-value-black {
  color: $black-90;
  @include fontSize(13);
  @include lato-regular();
}

.label-email-blue {
  color: $dodger-blue;
  @include fontSize(12);
  @include lato-regular();
}

.blank-data {
  color: $black-90;
}

.file-btn {
  @include lato-bold();
  @include fontSize(13);
  @include padding(8, 23);
  border: none;
  border-radius: 2px;
  color: $white;
  background-image: linear-gradient(104deg, $warm-grey-three, $greyish-brown);

  &:hover {
    color: $white;
  }
}

.view-more {
  @include lato-regular();
  @include fontSize(10);
  text-decoration: underline;
  color: #ff0000;
  @include margin(10, 5);
  @extend .cursor-pointer;
}

.f-12 {
  @include fontSize(12);
}

.f-14 {
  @include fontSize(14);
}

.f-11 {
  @include fontSize(11);
}

.f-16 {
  @include fontSize(16);
}

.f-22 {
  @include fontSize(22);
}

.m-t-n3 {
  margin-top: -3px;
}

ngb-modal-backdrop {
  &.modal-backdrop {
    z-index: 99991 !important;
  }
}

ngb-modal-window {
  &.modal {
    z-index: 99992;
  }

  .modal-content {
    box-shadow: 0 3px 5px 0 $light-black;
  }
}

//for interview rating popup
.rating-modal-editor {
  .angular-editor-toolbar {
    display: flex !important;
    justify-content: center;

        @include mobile {
            flex-wrap: wrap;
        }
    }

    .angular-editor-toolbar .angular-editor-toolbar-set:nth-last-child(-n+3) {
        display: none !important;
    }
    .angular-editor-toolbar .angular-editor-toolbar-set:nth-last-child(-n + 3) {
        display: none !important;
    }


    .angular-editor-toolbar .angular-editor-toolbar-set:not([style*="display:none"]):not([style*="display: none"]),
    .select-button {
        display: flex !important;
    }

    .angular-editor-toolbar .angular-editor-toolbar-set:nth-last-child(-n+3) {
        display: none !important;
    }

    .angular-editor-toolbar .angular-editor-toolbar-set:nth-last-child(-n + 3) {
        display: none !important;
    }
    .angular-editor
        .angular-editor-wrapper.show-placeholder
        .angular-editor-placeholder {
        @include fontSize(13);
    }
    .angular-editor .angular-editor-wrapper .angular-editor-placeholder {
        padding-top: calculateRem(11) !important;
        padding-bottom: calculateRem(8) !important;
    }
    .angular-editor-toolbar .angular-editor-toolbar-set {
        margin-right: 0 !important;
    }
    .angular-editor .angular-editor-wrapper .angular-editor-textarea {
        font-size: calculateRem(13) !important;
        resize: none;
    }
    .angular-editor-textarea:after {
        cursor: auto !important;
    }
}

.interview-provide-rating-modal {
    .modal-dialog {
        max-width: 61rem !important;

        @include tabletOnly {
            max-width: 45rem !important;
        }

        @include mobile {
            max-width: 45rem !important;
        }
    }
    @extend .rating-modal-editor;
}

.interview-rating-details-modal {
  .modal-dialog {
    max-width: 23rem !important;

    @include hd {
      max-width: 21rem !important;
    }
  }
}

.interview-rating-modal {
  .modal-dialog {
    max-width: 23rem !important;

    @include hd {
      max-width: 21rem !important;
    }
  }
}

.confirm-joining-date-popup-div {
  .modal-dialog {
    max-width: 21.3rem !important;
  }
}

.m-r-20 {
  margin-right: calculateRem(20);
}

.m-l-20 {
  margin-left: calculateRem(20);
}

.popup-header {
  @include fontSize(18);
  @include lato-bold;
  color: $black;
}

.min-w-100 {
  min-width: calculateRem(100);
}

.fs-12 {
  @include fontSize(12);
}

.fs-18 {
  @include fontSize(18, true);
}

input[type="number"].hide-arrow::-webkit-inner-spin-button,
input[type="number"].hide-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.toast-container {
  @include lato-regular();

  .large-toast {
    width: 400px;
  }
}

.workbookEditModal .modal-dialog {
  max-width: 600px;
}

.resume-btns {
  @include flexbox();
  @include justify-content(center);
  @include margin(33, 0);

  button {
    min-width: calculateRem(100);
    @include lato-bold();
    @include margin(0, 0, 0, 35);
    @include padding(10, 22);
    border-radius: calculateRem(6);
    letter-spacing: 0.14px;
    @include fontSize(18);
    transition: 0.3s;
  }

  .reset-btn:hover {
    color: $warm-grey-two;
    background-color: transparent;
  }

  .reset-btn {
    border: solid calculateRem(2);
    @include padding(10, 50);
    @include fontSize(14);
  }

  .save-btn {
    border-color: $pinkish-red;
    background-color: $pinkish-red;
    box-shadow: 0 10px 20px 0 $box-shadow-red;
  }

  .blue-button {
    box-shadow: 0 1px 2px 0 $black-20;
    background-image: $button-blue-4;
    color: $white;
    outline: 0;
    border: none;
  }
}

.table {
  th {
    font-size: calculateRem(12);
    background-color: #f8f9fa;
  }

  th,
  td,
  tr {
    @include lato-regular();
    border: solid 1px #e2e3e3 !important;
  }

  tbody {
    td {
      font-size: calculateRem(13);
      background-color: white;
    }

    .disabled {
      background-color: #eeee !important;
      opacity: 0.6;
    }
  }
}

//common confirmation popup style
.confirmation-popup-div {
  @include lato-regular();

  .heading {
    @include lato-bold();
    @include fontSize(18);
  }

  .body {
    @include fontSize(14);
    @include margin(16, 0, 0, 0);
  }

  .actions {
    @include margin(32, 0, 0, 0);
  }

  .btn {
    @include borderRadius(6);
    min-width: calculateRem(160);
    @include padding(0, 20);
    @include height(44);
    @include margin(0, 5, 0, 5);

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .save-btn {
    @include lato-bold();
    @include fontSize(18);
    color: $white;
    background-color: $pinkish-red;
    border-color: $pinkish-red;
    box-shadow: 0 10px 20px 0 $box-shadow-red;

    &.save-blue-btn {
      background-image: $button-blue;
      background-color: transparent;
      border-color: transparent;
      box-shadow: 0 10px 20px 0 transparent;
    }
  }

  .cancel-btn {
    @include lato-bold();
    @include fontSize(14);
    @include width(148);
    color: $warm-grey-two;
    background-color: $white;
    border: 2px solid $warm-grey-two;
  }

  .file-upload-section {
    @include lato-regular();
    .heading {
      @include lato-bold();
    }
    .content {
      @include lato-regular();
    }
    .parsing-status-area {
      .content {
        @include lato-regular();
      }
      .large-loader {
        border: 2px solid $light-gray;
        border-top: 2px solid $dodger-blue;
      }
      .close-btn {
        background-image: linear-gradient(104deg,$blue,$blue 100%);
        @include lato-bold();
      }
    }
    .upload-area {
      .upload-btn {
        background-image: linear-gradient(104deg,$blue,$blue 100%);
        @include lato-bold();
      }
    }
  }
}

.ng-select {
  @include lato-regular();
  outline: none;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #ccc;
  box-shadow: none;
}

.ng-select-container .ng-value-container {
  .ng-placeholder {
    color: $black-50 !important;
  }
}

input::placeholder,
textarea::placeholder {
  color: $black-50 !important;
}

.form-control {
  ::placeholder {
    color: $black-50 !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

a[disabled="true"] {
  pointer-events: none;
}

.disabled-icon {
  opacity: 0.5;
  cursor: default !important;
}

.count-badge-section {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  border-radius: calculateRem(9);
  background-color: $green2;
  font-size: calculateRem(12);

  @include desktop {
    @include padding(1, 6, 2, 6);
  }

  @include hd {
    @include padding(2, 6, 1, 6);
  }

  @include hdPlus {
    padding: calculateRem(1) calculateRem(6);
  }

  text-align: center;
  color: $white;
  @include lato-bold();
}

.count-badge-section-disabled {
  background-color: $greyish-two !important;
}

.small-icon {
  @include margin(0, 5, 0, 5);
  @include width(16);
  @include height(16);
}

.small-icon1 {
  @include margin(0, 3, 0, 5);
  @include width(22);
  @include height(16);
}

.note_content {
  @include lato-italic();
  @include fontSize(12);
}

.clear-icon {
  @extend .cursor-pointer;

  img {
    width: calculateRem(14);
  }
}

.submit-section {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
}

.button-save {
  &.btn:hover {
    color: $white !important;
    box-shadow: 0 1px 2px 0 $black-20;
  }

  height: calculateRem(44);
  @include borderRadius(6);
  background-image: $button-blue;
  // @include padding(11, 17);
  color: $white;
  @include fontSize(18);
  @include lato-bold();
  text-align: center;
  letter-spacing: 0.14px;

  &.red-btn {
    background-image: $button-red;
  }
}

.button-cancel {
  height: calculateRem(44);
  @include borderRadius(6);
  // @include padding(11, 17);
  color: $greyish-brown;
  @include fontSize(14);
  @include lato-bold();
  text-align: center;
  border: 1px solid $greyish-brown;
  outline: 0;
  letter-spacing: 0.14px;
}

.refresh-icon {
  @extend .cursor-pointer;
}

.email-popup {
  .font-style {
    @include fontSize(13);
    @include lato();
  }

  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    background: $white;
    @extend .font-style;
    @include flexbox();
    @include flex-direction(row-reverse);
    margin-top: 0;
  }

  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value
    .ng-value-icon.left {
    visibility: hidden;
  }

  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value
    .ng-value-icon.left::before {
    visibility: visible;
    display: inline-block;
    @include width(10);
    @include height(10);
    content: "";
    background: url("./assets/images/close.svg") no-repeat center;
    background-size: calculateRem(10) calculateRem(10);
  }

  .ng-select .ng-select-container .ng-value-container .ng-input > input {
    @extend .font-style;
  }

  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value
    .ng-value-icon {
    padding: 1px 1px;
  }

  .ng-select .ng-clear-wrapper {
    display: none;
  }

  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-input {
    padding-bottom: 6px;
  }
}

app-single-date-picker-component {
  .icon-class {
    padding-left: calculateRem(25);
  }

  .form-control {
    @include height(40);
    @include borderRadius(6);
    padding-left: calculateRem(15);
  }
}

.radio-large {
  @include width(18);
  @include height(18);
  bottom: 0;
}

.radio-text {
  @include lato-regular();
  @include fontSize(13);
  margin-left: calculateRem(10);
}

.popover {
  z-index: 10000 !important;
}

.optional-feild {
  @include lato-italic();
  @include fontSize(11);
  color: $light-black;
  @include letterSpacing(0);
}

.create-n-schedule-candidate {
  .wcs-label {
    @include fontSize(12);
    @include lato-bold();
    color: $light-grey;
  }

  .form-control {
    background-color: $white !important;
  }

  .disable-bg {
    background-color: $disabled !important;
    pointer-events: none;

    .border-right {
      border-right: 1px solid $black-20 !important;
    }
  }

  .disabled-form-control{
    .form-control,.input-section  {
      @extend .disable-bg;
    }
  }
}

.disable-bg {
  background-color: $disabled !important;
  pointer-events: none;

  .border-right {
    border-right: 1px solid $black-20 !important;
  }
}

.optional-field {
  @include lato-italic();
  @include fontSize(11);
  color: $light-black;
  @include letterSpacing(0);
}

.offer-letter-modal {
  .modal-dialog {
    max-width: calculateRem(782);
  }
}

.custom-style-ng-select {
  .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input {
    top: unset;
    padding-left: calculateRem(10);
    padding-right: calculateRem(50);
  }
}

.interviewer-driven-select {
  .modal-dialog {
    max-width: calculateRem(650);
  }
}

.info-red {
  @include lato-italic();
  @include fontSize(11);
  @include align-self(flex-end);
  color: $info-red;
  text-align: center;
}

legend {
  @include height(0);
  @include margin(0);
  overflow: hidden;
}

.job-post-form-editor {
  .angular-editor-toolbar {
    .preview-btn {
      background-color: $white;
      vertical-align: middle;
      border: 1px solid #ddd;
      padding: 0.38rem 0.4rem;
      min-width: 2rem;
      float: left;
      @include fontSize(11);
    }
  }

  .angular-editor .angular-editor-wrapper p {
    margin-bottom: 1rem !important;
  }
}

.refer-candidate {
  .modal-dialog {
    max-width: calculateRem(860);
  }

  .modal-body {
    @include padding(25);
  }
}

.referral-bonus-modal {
  .modal-lg,
  .modal-xl {
    @include desktop {
      max-width: 538px;
    }

    @include fullHd {
      max-width: 638px;
    }

    @include quadHd {
      max-width: 800px;
    }
  }
}

.referral-bonus-popup {
  .ng-select .ng-select-container {
    min-height: calculateRem(40);
  }

  .ng-select.ng-select-single .ng-select-container {
    @include height(40);
  }
}

.disable-feature {
  opacity: 0.4;
  pointer-events: none;
}

.email-popup {
    .to-list {
        .ng-select-container .ng-value-container {
            .ng-placeholder {
                color: $pinkish-red  !important;
                @include fontSize(12);
                @include lato();

            }
        }

        .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
            top: unset;
        }
    }
}

.email-editor-modal {

    .modal-lg,
    .modal-xl {
        @include desktop {
            max-width: 800px;
        }

        @include hdPlus {
            max-width: 1140px;
        }
    }

    @include hdPlus {
      max-width: 1140px;
    }
}

.email-flex-wrap {
    .email-alignment {
        .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
            flex: unset;
        }
    }

    .cc-list-alignment {
        @extend .email-alignment;
    }

    .bcc-list-alignment {
        @extend .email-alignment;
    }

    .to-list-alignment {
        @extend .email-alignment;
    }
}

.multi-select-checkbox-dropdown {

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
        font-weight: unset;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
        top: unset;
        padding-right: calculateRem(50);
    }

    .ng-select .ng-select-container {
        height: calculateRem(40);
        line-height: calculateRem(26);
        @include fontSize(13);
        border-radius: calculateRem(6);

        .ng-value-container {
            padding-left: calculateRem(15);

            input {
                padding-left: calculateRem(5);
            }
        }
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
        .ng-value {
            display: none;
        }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
        padding-left: 10px;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        display: flex;
        align-items: center;
        gap: calculateRem(7);
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
        color: $gray8;
    }
    .ng-select .ng-clear-wrapper{
      display: none;  
    }
}

.donut-chart-wrapper {
    .apexcharts-pie-series {
        cursor: pointer;
    }

    .apexcharts-legend {
        justify-content: center !important;
        top: 0 !important;
    }

    .arrow_box {
        @include fontSize(12);
    }

    .completed-report-chart{
        max-width: 20ch;
    }
    .cr-label {
        white-space: break-spaces;
    }
}
.source-prompt-checkbox {
    background: $gray5;
    border-radius: 7px;
    @include fontSize(12);
    @include lato();
}
 
.source-prompt-modal {
  .modal-dialog {
    @include tablet {
      max-width: calculateRem(480);
    }
  }
}

.previous-interview-wrapper {
  @include lato();
  @include fontSize(12);
  color: $black-60;
}

.previous-interview-visiblity {
  img {
    @include width(15);
    @include height(15);
  }
}

.overflow-content-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.interviewer-add-edit-modal {
  .modal-dialog {
    @include tablet {
      max-width: calculateRem(570);
    }
  }
}

.vt-head-icon {
  @include width(16);
  @include height(16);
}

.user-dropdown {
  margin-left: auto;
  @include lato-medium();
  @include fontSize(14);
  @include flexbox();

    .dropdown-toggle {
      @include padding(6, 8);
      @include flexbox();
      @include align-items(center);

      &::after {
        border-top: 0.43em solid;
        border-right: 0.4em solid transparent;
        border-left: 0.4em solid transparent;
        color: #343434;

        @include mobile {
          content: initial;
        }
      }
    }
    .dropdown-menu.show {
      right: 17px;
      left: initial;
    }

    .dropdown-menu {
      .dropdown-item {
        @extend .cursor-pointer;

        &:active {
          background-color: $dropdown-item-color;
        }
      }
    }

    .username {
      color: $black;
      @include fontSize(14);
      margin-right: calculateRem(16);

      @include mobile {
        content: initial;
      }
    }

    .dropdown {
      @include fontSize(13);
      @include lato-bold();
      font-style: normal;
      color: $black;
      min-width: calculateRem(150);
      position: absolute;
      top: calculateRem(50);
      right: calculateRem(10);
      padding: calculateRem(10);
      border-radius: 5px;
      border: 1px solid $box-shadow;
      background-color: $white;
      z-index: 9;
    }

    .user-avatar {
      @include margin(0, 15, 0, 10);
      height: 30px;
      width: 30px;
      border-radius: 50%;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);

      @include mobile {
        margin-right: 0;
      }
    }

}

.page-add-button {
  box-shadow: 0 1px 2px 0 $black-20;
  background-image: linear-gradient(100deg, $btn-blue, $btn-blue-2);
  color: $primary-btn-font-color;
}

.email {
  color: $email-font-color !important;
}

.dropdown-toggle {
  &::after {
    color: $dropdown-arrow !important;
  }
}

.navbar-nav {
  .menu-container {
    @include align-items(center);
    border-radius: 4px;
    &.main-list:hover {
      background-image: $grey-gradient;
    }

    &.active-list {
      background-image: $grey-gradient;
      @include lato-bold();
    }
    &.active-sub-list {
      background-color: $white;
    }
  }
  .menu-item {
    @include lato-regular();
    &:hover {
      color: $menu-hover-selected-btn !important;
    }
    &.active {
      color: $menu-hover-selected-btn !important;
    }
  }
}

.nav-menu-selected {
  filter: brightness(0) saturate(100%) invert(25%) sepia(89%) saturate(6940%)
    hue-rotate(356deg) brightness(108%) contrast(121%);
}

.blue-icon {
  filter: invert(54%) sepia(58%) saturate(1683%) hue-rotate(159deg)
    brightness(91%) contrast(106%) !important;
}

.theme-coloured-image {
  filter: brightness(0) saturate(100%) invert(29%) sepia(0%) saturate(1864%)
    hue-rotate(142deg) brightness(102%) contrast(80%);
}

.filter-buttons {
  .apply-filter {
    background-color: $filter-btn !important;
  }
}

.table-row {
  @include lato-regular();
  box-shadow: 0 0 2px 0 $black-25;
  .name {
    @include lato-bold();
    @include fontSize(16);
  }
  .label {
    color: $black-60;
  }
  .content {
    color: $black-80;
    &.bold {
      @include lato-bold();
      color: $black-80;
    }
    &.highlight {
      color: $black-90;
    }
  }
}

.label-tag-container {
  @include borderRadius(3);
  line-height: 18px;
  .label-tag {
    @include lato-bold();
    @include fontSize(11);
  }
  .edit-section {
    .dropdown-menu {
      @include fontSize(12);
      @include lato-regular();
    }
  }
}

.outlined-label-tag {
  background-color: transparent !important;
  color: $outlined-tag !important;
  border: 1px solid $outlined-tag-border;
}

.page-heading {
  @include lato-bold();
  @include fontSize(24);
  color: $greyish-brown;
}
.search {
  h2 {
    @include lato-regular();
  }
  .search-input {
    @include lato-regular();
  }
}

.search-form {
  .filter-heading {
    @include lato-bold();
  }
  .filter-list {
    > ul {
      li {
        @include lato-regular;
        > div {
          > span {
            @include lato-regular;
          }
        }
      }
    }
  }
  .search-input {
    input {
      @include lato-regular;
    }
    .searched-tags {
      @include lato-regular();
      .tags-applied {
        @include lato-italic;
      }
    }
    .searched-tags-dropdown {
      .searched-tags-button {
        @include lato-regular;
      }
    }
  }
  .sortby-wrap {
    .sortby-lablel {
      @include lato-regular();
      @include lato-italic();
    }
    .sort-button {
      @include lato-bold();
    }
  }
  .show-filters {
    @include lato-regular();
  }
  .filters-applied {
    .filters-name {
      @include lato-regular;
    }
  }
  .apply-filter {
    @include lato-bold();
  }
  .clear-filter {
    @include lato-bold();
  }
  .dropdown-menu {
    @include lato-regular();
  }
  .ng-select {
    ::ng-deep .ng-option-label {
      @include lato-bold();
    }
  }
}

.header {
  @include lato-medium();
  box-shadow: 2px 0 3px 0 $box-shadow;
  background-color: $white;
  .search-bar {
    input {
      @include lato-regular();
    }
    input::placeholder {
      @include lato-italic();
    }
  }

  .search-result-box {
    .no-data-container {
      span {
        @include lato-regular();
        &.sy {
          @include lato-medium();
        }
      }
    }
  }
  .custom-link {
    @include lato-regular();
    color: $bright-blue;
  }
  .user-dropdown {
    @include lato-regular();
  }
}
.pagination {
  @include lato-regular();
  color: rgba(0, 0, 0, 0.93);  
}

//employee referrals-table referal component
.tab-div {
  .nav-link {
    &.active {
      color: $red-ee !important;
    }
  }
  .active {
    color: $red-ee !important;
  }

  .active::before {
    content: "";
    background-color: $red-ee;
  }
  .lato-font-18 {
    @include lato();
  }
}
.referral-bonus-common {
  .tab-div .nav-link:not(.active) {
    color: $black-60 !important;
  }
}

//common referral bonus row component-rejct buttn

.center-align-flex{
  display: flex;
  align-items: center;
  justify-content: center;
}

.reject-btn {
  @extend .secondary-btn;
  @extend .center-align-flex;
  background-image: none;
  color: #fff !important;
  background-color: #ed202a !important;
  border-color: #ed202a !important;
  padding: 0px 11px;
  margin: 3px;
}
.blue-font {
  color: $link-blue;
}


//pop-up style -referal bonus-pop-up component
.referral-bonus-popup-wrapper {
  .referral-title {
    @include lato-bold();
  }
  .ref-name {
    @include lato-bold();
  }
  .ref-email {
    color: $dodger-blue;
    @include lato-regular();
    }

  .referral-bonus-contents {
    
    .ref-input {
      @include lato-regular;
    }
    .ref-label {
      @include lato-bold;
      color: $black-60;
    }
    .ref-item {
      @include lato();

    }
  }
  .red-btn-shadow{
    box-shadow: 0 10px 20px 0 $box-shadow-red  !important;
 }
} 

//common list item row component

.blue-font {
  color: $link-blue;
}

//Notify acccounts

.confirmation-popup-div {
  @include lato-regular();

  .heading {
    @include lato-bold();
  }

  .save-btn {
    @include lato-bold();
    background-color: $pinkish-red;
    border-color: $pinkish-red;
    box-shadow: 0 10px 20px 0 $box-shadow-red;
  }

  .cancel-btn {
    @include lato-bold();
  }
}

//edit-interview-schedule edit interview component

.scheduleInterviewForm {
  .popup-header {
      @include lato-bold();
  }

  .label-feild {
      @include lato-bold();
      color: $black-60;
  }

  .optional-feild {
      @include lato-italic();
  }

  .feild-value {
      @include lato-regular();
  }


  .btn-cancel {
      @include lato-bold();
  }

  .red-btn {
      background-color: $pinkish-red;
      @include lato-bold();
  }

  .skill-display {
      @include lato();
  }

  .no-slots-error {
      @include lato-regular();
  }

  .slot-section {
      .slots {
          .slot-item {
              @include lato-regular();

              &:hover {
                border-color: $blue;
            }

            &.active {
                background-color: $blue;
                border-color: $blue;
            }
          }
      }
  }
}


//update interview status-comment popup component

.comment-popup {
  @include lato-regular();

  .modal-title{
    @include lato-bold();
  }

  .modal-body{

    .label {
      @include lato-bold();
      color: $black-60;
    }

    .label-italics {
      @include lato-italic();
    }

    .form-control {
      @include lato-regular;
    } 
    .action-div {
      .lg-save-btn{
        @include lato-bold();
        background-color: $pinkish-red;
        border-color: $pinkish-red;
      }
    }

  }

::ng-deep.ng-select {
  @include lato-regular();
  
}
.multi-select-items {
    @include lato-regular();
  }


.error-msg {
  color: $pinkish-red;
  @include lato-regular();
}
.info-box-general{
  box-shadow: 0 1px 3px 0 $light-black;
  background-color: $info-grey;
  @include lato-regular();
  
}
}
//commom-interview component

.interview-list {
  .checkbox-div {
    @include lato;
  }
  .select-all{
    color:$dodger-blue;
  }
}
//confirm joining date
.confirm-btn {
  @include fontSize(9);
}

//candidate details -candidate-header component

.resume-header {
  .name-text {
    @include lato-bold();
  }

  .experience-label {
    @include lato-regular;
  }
}
.skills-container {
  @include lato();
  .pale-black {
    @include lato-regular;
  }
  .skills {
    .skill {
      border: 1px solid $greyish-three;
      background-color: $white;
    }
    .skills-button {
      color: $dodger-blue;
    }

    .skills-applied {
      @include lato-italic();
    }
  }

  .skills-dropdown{
    .skill{
      background-color:$primary-btn-font-color;
    }
  }
}
.dropdown-menu {
  .dropdown-item {
    @include lato-regular();
  }
}

//candidate personal tab - candidate-personal-details component

.candidate-personal-tab{
  @include lato-regular();
}

//tab section component

.tab-div {
  @include lato-bold();
}


.app-loader-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999999;


  .lds-roller {
      display: inline-block;
      position: absolute;
      width: 80px;
      height: 80px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
  .lds-roller div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
  }
  .lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #fff;
      margin: -4px 0 0 -4px;
  }

  .lds-roller div:nth-child(1) {
      animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
      top: 63px;
      left: 63px;
  }
  .lds-roller div:nth-child(2) {
      animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
      top: 68px;
      left: 56px;
  }
  .lds-roller div:nth-child(3) {
      animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
      top: 71px;
      left: 48px;
  }
  .lds-roller div:nth-child(4) {
      animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
      top: 72px;
      left: 40px;
  }
  .lds-roller div:nth-child(5) {
      animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
      top: 71px;
      left: 32px;
  }
  .lds-roller div:nth-child(6) {
      animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
      top: 68px;
      left: 24px;
  }
  .lds-roller div:nth-child(7) {
      animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
      top: 63px;
      left: 17px;
  }
  .lds-roller div:nth-child(8) {
      animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
      top: 56px;
      left: 12px;
  }
  @keyframes lds-roller {
      0% {
      transform: rotate(0deg);
      }
      100% {
      transform: rotate(360deg);
      }
  }
}


//candidate-detail component

::ng-deep .details-container{
  @include lato-regular();
}
//candidate rating component

.black-btn {
  @include lato-bold();
}
.rating-modal-div {
  @include lato-regular();
  .modal-title {
    @include lato-bold();
  }
  .label {
    @include lato-bold();
  }
  .attachment-text {
    @include lato-regular();
  }
  .save-btn,
  .close-btn {
    @include lato-bold();
    background-color: $pinkish-red;
    border-color: $pinkish-red;
  }
}
//interview detail component

.interview-details {
  @include lato-bold();
}
.interview-section {
  .accordion-button {
      @include lato-bold();
  }
}
.interview-decision{
  @include lato-bold();
}
.rating-details-heading{
  @include lato-bold();
}
.rating-details{
  @include lato-regular();
}
.attachment-heading{
  @include lato-bold();
}
.comments-heading{
  @include lato-bold();
}
.attachment-viewer {
  background-image: $button-blue-4; 
}

//schedule invite detail component

.schedule-invite-section{
  @include lato();
}
//resume detail component

.candidate-page {
  @include lato-regular();
}
//hiring-detail component

.details-section{
  .sub-section{
    .sub-heading{
      @include lato-bold();
    }
  } 
  .payslip-viewer {
    background-image: $button-blue-4;
  } 
}


//activity-section component

.activity-tab {
  @include lato-regular();
  .heading {
    @include lato-bold();
  }

  .label-bold {
    @include lato-bold();
  }

  .link {
    color: $red;
  }

  .custom-btn {
    @include lato-bold();
    background-image: linear-gradient(100deg, $btn-blue, $btn-blue-2);
  }
}

//edit candidate detail-common candidate scss

.candidate-form,
.edit-candidate-modal {
    label {
        @include lato-bold;
        color: $black-60;
    }
    .section-header {
        @include lato-bold;
    }
    .form-control {
        @include lato-regular;
    }
    .input-prefix {
        .ng-select {
            @include lato-regular();
        }
    } 
}



//comment component

.optional-feild {
  @include lato-italic();
}


.btn.btn-uploader {
  background-image: $button-blue;
}
// edit candidate details-candidate component

.candidate-form , .edit-candidate-modal {
  
    .btn-submit {
      background-image: $button-blue;
      @include lato-bold();
      &.add-more {
        @include lato-regular();
      }
    }
    .btn-cancel {
      @include lato-bold();
    }
  
    .skill-button {
      padding: 5px 6px 4px 7px;
      opacity: .25;
      img{
        width: 23px;
      }
    }
}

//basic-info-component

.parser {
  color: $blue-active;
  @include lato-regular();
}

//send schedule invite- schedule invite form component

.schedule-invite-form {
  .form-field {
    .col-form-label {
      @include lato-bold();
    } 
  }
}

//dropdown scss

.selected-view {
  @include lato-regular();
}

//date picker popup component
  
  .date-picker-input {
      @include lato-regular();
  }

  ngx-daterangepicker-material {
    .md-drppicker {
       .buttons {
         .buttons_input {
           .btn {
             background-color: $pinkish-red;
             @include lato-bold();
           }
           .clear {
             @include lato-bold();
           }
         }
       }
     }
   }
   
//list component -candidate

  .WB-section {
    .WB-content {
      .select-all {
        color: $dodger-blue;
      }
    }
  }
  .WB-list {
    @include lato();
  }
//new hires-new hire intimation component


.intimations-list {
    .month-text {
        @include lato-bold();
    }
}

//date header component

.date-container {
  @include lato-regular();
}

//kebab menu component

.kebab-menu {
  .dropdown-menu {
    .dropdown-item {
      @include lato-regular();
    }
  }
}
//candidate action component

.action-header {
  @include lato-bold();
  .action {
    @include lato-bold()
  }
  .total-candidates {
    @include lato-regular();
  }
}
.action-body {
  @include lato-regular();
  .form-control {
    @include lato-regular();
  }
  .action-table-heading {
    @include lato-bold();
  } 
}

//bulkupload-bulkupload component

.bulk-upload-container {
  .bulk-upload {

  .heading {
    @include lato-bold();
  }

  .file-drop {
    @include lato-regular();  
    .upload-btn {
      background-image: linear-gradient(104deg,$blue,$blue 100%);
    }
  }

  a {
    color: unset !important ;
  }
  
  .hint-text {
    color: $blue !important;
    @include lato-bold;
    &.underline {
      color: $blue !important;
    }
  }
  .file-list {
    @include lato-regular();
    .table-heading {
      @include lato-bold();
    }
  }
  }
}

//bulk upload detail component

.bulk-upload-detail-container {
  @include lato-regular();
  .summary-container {
    .bold-text{
      @include lato-bold();
    }
    .file-name {
      @extend .bold-text;
    }
    .summary-text {
      @include lato-regular();  
    }
    .failed-count {
      @extend .bold-text;
    }
    .failed-text {
      @include lato-regular();
    }
    .status-label {
      @include lato-regular();
    }
  }

  .table-container {
    thead {
      tr, th {
        @include lato-bold();
      }
    }
    tbody {

      tr, td {
        @include lato-regular();
      }
    }
  }
}

//create workbook popup-workbook craete component
.addWorkBookForm {
  .btn-cancel {
      @include lato-bold();
  }

  .red-btn {
      background-color: $pinkish-red;
      @include lato-bold();
  }
  .skill-display {
    @include lato();
  }
}
//workbook candidate component

.list-candidates {
  @include lato();
}

.popup-header {
  @include lato-bold();
}

.label-feild {
  @include  lato-bold();
}

.feild-value {
  @include lato-regular();
}

.no-data-container {
  @include lato();
  .candidates-link {
    color: $dodger-blue;
    @include lato-italic();
  }
}

// Job post custom nav component
.custom-nav {

  .nav-link {
    color: $grey-text;
    @include lato-regular();
    text-shadow: -0.25px -0.25px 0 transparent, 0.25px 0.25px transparent;
    @include borderRadius(4);
  }


  .custom-nav-list {
    .nav-item {
      @extend .cursor-pointer;

      &:hover {
      
          .nav-link {
            color: $pinkish-red;
            text-shadow: -0.25px -0.25px 0 $pinkish-red, 0.25px 0.25px $pinkish-red;
            background-image: linear-gradient(90deg,$gray11,$info-grey);
            box-shadow: 0 1px 1px 0 rgb(0 0 0 / 14%);
          }
        }

      &.active {
  
        .nav-link {
          color: $pinkish-red;
          text-shadow: -0.25px -0.25px 0 $pinkish-red, 0.25px 0.25px $pinkish-red;
          background-image: linear-gradient(90deg,$gray11,$info-grey);
          box-shadow: 0 1px 1px 0 rgb(0 0 0 / 14%);
        }
      }

    }

    .chevron::before {
      color: $pinkish-red;
    }

  }
}

//workbooks -workbook row component

.candidates-added {
  @include lato-bold();
}

.owners-dropdown {
  .owners-button {
    color: $dodger-blue;
    @include lato-italic();
  }
}

// email-candidate list item component

.content {
  @include lato();
}

// add candidate- workbook candidate component

.btn-add {
  background-image: $button-blue-2;
  @include lato-regular();
}
.addCandidatesForm {

  .btn-cancel {
    @include lato-bold();
  }

  .red-btn {
    background-color: $pinkish-red;
    @include lato-bold();
  }
  .candidates-row {
    .data-row {
      .name {
        @include lato-bold();
      }
    }
  }
}  

//update candidate and schedule interview-workbook create and schedule candidate component

.create-and-schedule-candidate-container {
  .status-container {
    @include lato-regular();
  }
  .optional-feild {
    @include lato-italic();
   }
}

//job post-edit job requisition-job post form component


.job-post-form-container {

  .optional-feild {
    @include lato-italic();
  }
  .multi-select-items {
    @include lato-regular();
  }
}
 
//job post form component

.langauges-selected {
  font-family: "Lato",sans-serif;
}

//job post header component
.header {
.text-content {
  .first-row {
    .job-title {
      @include lato-bold();
    }
  }
}
}
.update-popup {
   @include lato-regular();
  .modal-title{
    @include lato-bold();
  }
  .modal-body{
    .label {
      @include lato-bold();
    }
    .label-italics {
      @include lato-italic();
    }
  }
}

// job post view component


::ng-deep {
  .heading-text {
    @include lato-bold();
  }
}
:host::ng-deep {
  .wb-submit-section {
    .save-btn {
      border-color: $pinkish-red;
      background-color: $pinkish-red;
      box-shadow: 0 10px 20px 0 $box-shadow-red;
      @include lato-bold();
    }
    .draft-btn {
      @include lato-bold();
    }
  }
}

// Agencies-job post detail component

.job-main-content {
  .detail-value {
    @include lato-regular();
  }
}

//job-post agencies component

.job-main-content {
  .label-value {
    @include lato-bold();
  }
}

//agencies details component

.no-agencies {
  @include lato-bold();
}
.agencies-data {
  @include lato-regular();
  .agency-name {
    @include lato-bold();
  }
}
//manage agencies component

.manage-agencies-container {
  @include lato-bold();
}

// Workbook - overview component

.overview-container {
  @include lato-regular();

  .wb-candidate-count {
    @include lato-bold();
  }

  .summary-heading {
    @include lato-bold();
  }

}

// workbook - overview tile component

.card {

  .card-body {
      .card-title {
          @include lato-bold();
      }
      .row {
          .content-1 {
              .sucess-count {
                  @include lato-bold();
              }
          }

          .error-count {
              @include lato-bold();
          }
          .count-text {
              @include lato-regular();
          }
      }
  }
}

.toggle-text {
  color: $dodger-blue;
  @include lato-regular();
}
//workbook detail component

.request-salary-container {
  .workbook-page-header {
    @include lato-bold();
  }
}

//workbook basic info content component

.wcs-basic-info-container {
  .info-content {
    @include lato-italic();
  }
}
//workbook edit component

form {
  @include lato-regular();
  
}
::ng-deep.ng-select {
  @include lato-regular();
}

//Schdule invite section

.schedule-invite-item {
  .data-row {
    &.invite {
      .item-label {
        @include fontSize(12);
        @include lato();
        color: $light-grey;
      }
      .content-font {
        @include fontSize(14);
        @include lato();
      }
      .invite-content {
        @extend .content-font;
        color: $black;
      }
    }
  }
}

//workbook - schedule interview

.request-rating-container {
  .link {
      @include lato-italic();
      color: $dodger-blue;
  }

  .bold {
    @include lato-bold();
  }

  .error-msg{
      @include lato-italic();
  }
    
}
//WCS interview detail component

.wcs-interview-details-container {
  .txt-14 {
    @include lato-regular();
  }

  .toggle-text {
    color: $dodger-blue;
    @include lato-regular();
  }

  .recent-section {
    .title {
      @include lato-bold();
    }
  }
}

//candidates - update status popup component

.updateStatusForm {
  .label-value {
      @include lato-regular();
  }
  label {
    @include lato-bold;
  }
  .radio-text{
    @include lato-regular();
  }
  .checkbox-div {
    @include lato-regular();
  }
  ::ng-deep .ng-select {
    @include lato-regular();
  }
  .alert.alert-error {
    @include lato-regular();
  }
  .input-prefix {
    ::ng-deep .ng-select {
        @include lato-regular();
    }
  } 
  .button-save{
    box-shadow: 0 10px 20px 0 rgba(228, 30, 38, 0.21);
  }
}
.hint {
  @include lato-regular();
}
.btn-uploader {
  @include lato-bold();
}

//overview- jobpost header component

.header {
  .text-content {
    .first-row {
      .job-candidates-tagged {
        .candidates-count {
          @include lato-bold();
        }
        .candidates-tagged-text {
          @include lato-medium();
        }
      }
    }
    .second-row {
      .job-published {
       
        .publish-link {
          color: $bright-blue;
          @include lato-italic();
        }
      }
    }
  }
}

//jobpost candidate - jobpost candidates component

.job-main-content {
  .label-value {
    @include lato-bold();
  }
}

// dashboard widget component

.no-data-text{
  @include lato-regular();
}

//simple tab section component

.simple-tab-div {
  @include lato-regular();
  .nav-link {
    &.active {
      color: $pinkish-red;
      border-color: $pinkish-red;
    }
  }
}

//nav submenu component

.expand-submenu {
  .menu-item {
    @include lato-regular();
  }
}

//job candidate list component

.candidates-container {
  @include lato-regular();
  .no-data-container {
    @include flexbox();
    @include align-items(center);
    // @include lato-regular();
    font-weight: 400;
    @include fontSize(18);
    height: unset;
    color: $black-value;
    flex-direction: column;
    margin-top: 5%;

    .no-data-actions {
      margin-top: calculateRem(20);
    }

    .blue-link {
      color: $button-blue-5;
      text-decoration: none;
      @extend .cursor-pointer;
    }
  }
}

//job candidate row component

.outlined-btn {
  color: $btn-blue;
  border: 1px solid $btn-blue;
  @include lato-bold();
}
//wcs-listing-page component

.list-candidates {
  @include lato();
}
//time picker popup component

.timepicker-popover{
  @include lato();
  .apply-btn {
    background-color: #ed202a;
  }
}

//workbook schedule interview component

.submit-section {
  .save-btn {
      border-color: $pinkish-red;
      background-color: $pinkish-red;
      box-shadow: 0 10px 20px 0 $box-shadow-red;
  }
}

//workbook schedule interview edit component
form {
  @include lato();
}
.btn-submit {
  &.red-btn {
    background-image: $button-red !important;
  }
}

//workbook interviewer driven schedule component

.create-candidate-container{
  @include lato();
}
.link {
  color: $dodger-blue;
}
.duplicate-alert {
  @include lato-regular();
}

//time picker component


ngb-timepicker {
      .ngb-tp {
          .ngb-tp-meridian {
              .btn-outline-primary {
                  border-color: $light-cyan;
                  background-color: $blue-3;
              }
          }
    }
}

//offer detail component

.offer-details-section {
  @include lato();
  .offer-action-wrap {
    .action-btn-approve,.send-offer-btn {
        background-image: linear-gradient(105deg, $btn-blue, $btn-blue-2);
    }
  }
  .attachments {
    color: $dodger-blue;
  }
}
// workbook history section

.activity-history-container {
  .header-section {
    .log-title {
      @include lato-bold();
    }
  }
  .top-section {
    .username {
      @include lato-bold();
    }
    .link {
      @include lato-italic();
      color: $dodger-blue;
    }
  }
}

//candidate detail component

.activity-class {
  .tab-div .nav-link:not(.active){
    font-size: calculateRem(14);
    color: $black-60!important;
  }
}

//create offer component

.offerLetterForm {
  @include lato();
}

//referral detail component

.overflow-section {
  .email{
      color: $email-font-color !important;
  }
}

//email popup component

.email-popup {
  ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-star-inserted:last-child {
    color: $dodger-blue;
  }
  .save-btn {
    border-color: $pinkish-red;
    background-color: $pinkish-red;
    box-shadow: 0 10px 20px 0 $box-shadow-red;
    color: $white;
    @include fontSize(16, true);
    @include lato-bold();
  }
}

//offer detail component

.offer-details-section {
  @include lato();
  .offer-top-action-section {
    .create-btn {
      background-image: linear-gradient(100deg, $btn-blue, $btn-blue-2);
    }
    .offer-btn{
      background-image: linear-gradient(100deg, $btn-blue, $btn-blue-2);
    }
  }
}

//availability modal component

.availability-modal {
  @include lato();
}
.save-blue-btn {
  background-image: $button-blue;
}

//warning popup component

.blue-btn {
  background-image: $button-blue;
  @include lato-bold();
}

//edit schedule invite component

.schedule-invite-form-wrapper {
  @include lato();
}

//workbook row component

.workbook-section{
  .data-row {
    &.workbook {
      margin-top: calculateRem(-15);
    }
  }
}

.interview-row {
  .data-row {
    .interview-time {
      @include lato-bold();
    }
    .interview-latest-action {
      @include lato-bold();
    }
  }
}

//workbook send offer letter component

.request-rating-container {
  @include lato();
}

.refer-a-friend-popup {
  label {
    @include lato-bold();
  }
  .form-control {
    @include lato-regular;
  }
  ::ng-deep {
    .ng-select {
        .ng-select-container {
          @include lato-regular();
        }
    }
  }
  .input-prefix {
    ::ng-deep .ng-select {
      @include lato-regular();
    }
  }
  .eligibility {
    @include lato-bold();
  }
  .info-container {
    @include lato-regular();
  }
}

// activity history component

.no-data-log-wrapper {
  @include lato-regular();
}
 //workbook-candidate list item component
      
button {
  @include lato-bold();
}

.btn-no {
  @include lato-bold();
}

.btn-yes {
  background-color: $pinkish-red;
  box-shadow: 0 10px 20px 0 $box-shadow-red;
}

.resume-container {
  background-color: $pale-grey;
}

//side nav component 

.header {
  @include fontSize(14);
  .navbar-collapse {
    .menu-container {
      .nav-link {
        color: $black-90;
      }
    }
  }
}
//search sort filter component

.search-input {
  .searched-tags {
    top: calculateRem(16);
    .skill {
      color: $black-30;
    }

    .skill-item {
      color: $black-60;
    }

    .tags-applied {
      color: $black-30;
    }
  }

  .search-input-field {
    box-shadow: 0 0 3px 0 $black-15;
  }
}

//resume row component

.data-row {
  &.referral {
    background-color: $grey-background;
  }
}

.top-action-icon, .action-icon{
  background:none;
}

//interviewers row component

.data-row {
  &.interviewer {
    .item-label {
      color: $black-50;
    }
    .interviewer-content {
      color: $black-90;
    }
  }
}

//owner list dropdown view
.owner-section {
  .owners-dropdown {
    align-self: center;
    .main-content {
      text-align: left !important;
      color: $black !important;
    }
    .owners-button {
      vertical-align: baseline;
      @include padding(0);
      @include margin(0, 0, 0, 5);
      @include fontSize(14);
      text-decoration: underline;
      &:after {
        content: initial;
      }
    }
    .owner-menu {
      @include padding(0);
    }
  }

  .dropdown-menu {
    @include padding(0, 4);
    @include borderRadius(4);
    box-shadow: 0 4px 10px 0 $black-50;
    @extend .cursor-pointer;
    margin: calculateRem(2) !important;  
    top: 19px !important;
    bottom: auto !important;
    transform: none !important;
    &:before {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-left: 0.8rem solid transparent;
      border-right: 0.8rem solid transparent;
      border-bottom: 0.8rem solid $white;
      filter: drop-shadow(0px -1px 0px $black-1);

      @include absolute($top:-10px, $left: 2px);
    }
    .dropdown-item {
      @include padding(0, 6, 6, 6);
      box-shadow: 0 0 1px 0 $black-25;
      &:active {
        background-color: transparent;
      }
      &:hover {
        background-color: transparent; 
      }
    }

    li:last-child {
      .dropdown-item {
        &:after {
          border-bottom: none;
        }
      }
    }

    li:hover {
      background-color: $greyish-brown-li;
    }
  }
}

//selected candidate list component

.selected-candidate-list {
  .checkbox-div {
    @include lato;
  }
  .select-all{
    color: $dodger-blue;
  }
}

.custom-checkbox{
  &:checked {
    accent-color: $button-blue-5;
  }
}

.meet-link {
  color: $dodger-blue;
  @include lato-bold();
}

.cancel-bttn {
  color: $warm-grey-two;
  border: 1px solid $warm-grey-two;
}

//common input field 
app-input-field {
  ::ng-deep {
    .theme-based-input-field {
      &.floating-input {
        &:nth-child(even) {
          .input-field {
            padding-left: 3rem !important;
          }
        }
      }
      &:not(.floating-input) {
        &:nth-child(even) {
          label {
            padding-left: 3rem !important;
          }
        }
      }
    }
  }
}

.username-display {
  @include margin(3, 6, 3, 0);
  @include padding(5);
  font-size: calculateRem(12);
  border-radius: calculateRem(3);
  height: calculateRem(16);
  background-color: $white;
  color: $black-90;
}

.wb-candidate-count {
  color: $pinkish-red;
  @include fontSize(60);
  margin-bottom: -15px;
}

.wb-candidate-count-text {
  color: $black;
  @include fontSize(24);
}

.w-12 {
  width: 12%;
}

.w-88 {
  width: 88%;
}
.interview-details-container {
  .interview-details {
    @include fontSize(18);
  }

  .section-header {
    @include fontSize(18);
  }

  .label-text {
    @include fontSize(12);
    color: $black-60;
    font-weight: 700;
  }

  .content {
    @include fontSize(13);
    color: #000;
  }

  .label-value-main {
      color: $gray-14;
  }

  .save-btn {
    width: 180px;
    height: 44px;
    background-image: linear-gradient(104deg,$blue,$blue 100%);
    color: $white-two;
    @include lato-bold();
    @include fontSize(18);
    border-radius: calculateRem(6);
  }

  .page-add-button {
    background-image: linear-gradient(104deg,$blue,$blue 100%);
  }

}
