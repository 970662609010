@import "./utils";
@import "./variables.scss";

html {
  height: 100%;

  @include mobile {
    font-size: 14px;
  }
  @include tablet {
    font-size: 11px;
  }
  @include desktop {
    font-size: 13px;
  }
  @include hd {
    font-size: 14px;
  }
  @include hdPlus {
    font-size: 16px;
  }
  @include fullHd {
    font-size: 18px;
  }
  @include quadHd {
    font-size: 20px;
  }
  @include uHd {
    font-size: 22px;
  }
}

body {
  padding: 0;
  margin: 0;
  background-color: $app-bg;
}

#root {
  min-height: 100%;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-family: $roboto-regular;
}
