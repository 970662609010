@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

@mixin lato-regular() {
  font-family: "Lato", sans-serif;
  font-weight: 400;
}
@mixin lato-medium() {
  font-family: "Lato", sans-serif;
  font-weight: 500;
}
@mixin lato-bold() {
  font-family: "Lato", sans-serif;
  font-weight: 700;
}
@mixin lato-italic() {
  font-family: "Lato", sans-serif;
  font-style: italic;
}
@mixin lato() {
  font-family: "Lato", sans-serif;
}

@mixin sans-pro-regular() {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}
@mixin sans-pro-medium() {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
}
@mixin sans-pro-bold() {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
}
@mixin sans-pro-italic() {
  font-family: "Source Sans Pro", sans-serif;
  font-style: italic;
}
@mixin sans-pro() {
  font-family: "Source Sans Pro", sans-serif;
}