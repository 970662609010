// scss-docs-start color-variables
$white: #fff !default;
$black: #000 !default;
$orange: #ffa500;
$gray: #ddd;
$light-gray: #fcfcfc;
$dark: #1b2733;
$greyish: #b4b4b4;
$pinkish-red: #ed202a;
$blue-grey: #a6b2c4;
$light_green: #3bee16;
$red: #ff0000;
$green: #1b8c03;
$green2: #089f00;
$grey: #555555;
$blue: #3faaff;
$true-green: #1b8c03;
$marigold: #ffbd02;
$gray1: #dfdfdf;
$gray2: #b0bdcc;
$gray3: #e1e1e1;
$gray4: #dfecff;
$gray5: #eee;
$gray6: #f9f9f9;
$gray7: #dddddd;
$gray8: #333;
$gray9: #e8e8e8;
$gray10: #1a1a1a;
$gray11: #f3f3f3;
$gray12: #e4e4e4;
$gray-13: #898989;
$gray-14:#222222;
$gray-dark: #666666;
$light-blue: #eff4f9;
$light-blue2: #9fbed6;
$light-blue3: rgba(59, 179, 238, 0.2);
$blue-inactive: #2f8dbc;
$blue-active: #3cb3ee;
$dodger-blue: #42a7fe;
$duck-egg-blue: #e6f0fc;
$tag-gray: #e0e7ea;

$app-bg: #f7f9fc;
$light-cyan: #ced4da;
$light-red: rgba(255, 0, 0, 0.08);
$box-shadow: rgba(0, 0, 0, 0.1);
$box-shadow-red: rgba(228, 30, 38, 0.21);
$pale-grey: #f7f9fc;
$bright-blue: #3bb3ee;
$light-grey: rgba(0, 0, 0, 0.6);
$blue-2: #00b5f4;
$btn-grey: #7d7d7d;
$grey-label-bg: #737373;
$btn-blue: #3fabff;
$btn-blue-2: #3c8dfd;
$light-black: rgba(0, 0, 0, 0.4);
$pinkish-red: #ed202a;
$red-1: #ff4949;
$white-two: #ffffff;
$border-color: #d8d8d8;
$scroll-color: #7d7d7d;
$red-50: rgba(255, 0, 0, 0.5);
$grey-background: #f6f6f6;
$white-four: #f8f8f8;
$white-five: #fdfcfe;
$white-six: #ffffff80;
$warm-grey-three: #8f8f8f;
$greyish-brown: #595959;
$greyish-two: #b1b1b1;
$greyish-three: #b2b2b2;
$greyish-four: #f1f4f6;
$pale-grey: #f7f9fc;
$white-three: #ededed;
$grey-text: #4c4c4c;
$pinkish-grey: #bbbbbb;
$warm-grey-two: #727171;
$red-ee: #ee2b26;
$info-red: #791616;
$input-border: #e0e0e0;
$input-placeholder: #6d767e;
$grey-active: #eaeaea;
$grey-side-bar: #6d6d6d;
$grey-bg: #f0f0f0;
$olive-green: #588958;
$grey-info-bg: #f5f5f5;
$black-1: rgba(0, 0, 0, 0.1);
$black-12: rgba(0, 0, 0, 0.12);
$black-15: rgba(0, 0, 0, 0.15);
$black-17: rgba(0, 0, 0, 0.17);
$black-20: rgba(0, 0, 0, 0.2);
$black-25: rgba(0, 0, 0, 0.25);
$black-50: rgba(0, 0, 0, 0.5);
$black-30: rgba(0, 0, 0, 0.3);
$black-40: rgba(0, 0, 0, 0.4);
$black-23: rgba(0, 0, 0, 0.23);
$black-32: rgba(0, 0, 0, 0.32);
$black-60: rgba(0, 0, 0, 0.6);
$black-70: rgba(0, 0, 0, 0.7);
$black-79: rgba(0, 0, 0, 0.79);
$black-80: rgba(0, 0, 0, 0.8);
$black-81: rgba(0, 0, 0, 0.81);
$black-90: rgba(0, 0, 0, 0.9);
$black-91: rgba(0, 0, 0, 0.19);
$black-16: rgba(0, 0, 0, 0.16);
$black-93: rgba(0, 0, 0, 0.93);
$black-29: rgb(0 0 0 / 29%);
$black-18: rgb(0 0 0 / 18%);

$black-border: rgba(0, 0, 0, 0.08);
$greyish-brown-li: rgba(89, 89, 89, 0.15);
$grey-icon-bg: rgba(216, 216, 216, 0.2);
$light-red-arrow: #ff1700;
$bright-black: #2c2c2c;
$blue-3: #0d6efd;
$disabled: #dcdcdc;
$border-grey: rgba(0, 0, 0, 0.11);
$bright-green: #1aa400;
$border-dark-grey: rgba(151, 151, 151, 0.43);
$grey-background-1: #e8e8e8;
$alert-pink: rgba(255, 0, 0, 0.36);
$link-blue: #007de8;
$link-blue-2: #40abff;
$link-blue-3: #2f9cfe;
$black-gradient: linear-gradient(106deg, #7a7979, #595959);
$grey-gradient: linear-gradient(
  to right,
  rgba($black, 0.09),
  rgba(183, 183, 183, 0.05)
);
$button-blue: linear-gradient(103deg, #2e9cfe, #3685cb);
$button-red: linear-gradient(103deg, #ed202a, #ed202a);
$button-blue-2: linear-gradient(103deg, #3fabff, #3c8dfd);
$button-blue-3: linear-gradient(100deg, #3fabff, #3c8dfd 100%);
$button-blue-4: linear-gradient(135deg, #2e9cfe, #3685cb);
$button-blue-5: #2B7FFF;
// scss-docs-end color-variables
// scss-docs-start cursor-variables-map
$cursor-variables: pointer, crosshair, not-allowed, grabbing, grab, zoom-in,
  zoom-out !default;
// scss-docs-end theme-colors-map
$draft-color: #2c95b1;
$info-grey: #fdfdfd;
$bg-grey: #fbfbfb;
$aqua-blue-light: #bdedfe;
$aqua-blue-light-border: #80b6c9;
$dark-blue: #006589;
$red-30: rgba(255, 26, 26, 0.3);
$voilet-blue: #677bd7;
$black-35: rgba(0, 0, 0, 0.35);
$green-3: #679950;
$dark-blue-1: #34809c;
$blue-4: #4fa9df;
$pink: #fd8c8c;
$very-light-pink: #ffe7e7;
$yellow: #ffcb00;
$blue-63: rgba(63, 170, 255, 0.8);

$black-75: rgba(0, 0, 0, 0.75);
$platinum-grey: #e5e5e5;
$search-border: #DCF0F2;
$black-value: #333333;
$gray-value: #565656;

$dark-grey: #606060;
$blue-light: #eaf2ff;
$blaze-orange: #f46a06;

$warm-grey-50: rgba(151, 151, 151, 0.5);
$light-red-2: #ffcbcb;
$black-39: #393939;
$off-white: rgba(255, 255, 255, 0);
$black-97: #979797;

$warm-grey-50: rgba(151, 151, 151, 0.5);
$dropdown-item-color: #e9ecef;
$radio-black: #212529;

// Common variables
$primary-btn-font-color: #fff !default;
$email-font-color: #42a7fe !default;
$dropdown-arrow: #343434 !default;
$active-menu-item: #000 !default;
$menu-hover-selected-btn: #ed202a !default;
$filter-btn: #ed202a !default;
$outlined-tag: #4c4c4c !default;
$outlined-tag-border: rgba(255, 0, 0, 0.5) !default;
$active-tab-background-color: #ee2b26 !default;
$active-tab-font-color: #ee2b26 !default;
$pop-up-btn-bg-color:linear-gradient(103deg, #ed202a, #ed202a) !default;
